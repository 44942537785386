import { Hero, AgeDisc, GamesList, CTA, CookieModal, Contacts } from "./components";
import { ThemeProvider } from '@mui/material';
import theme from "./theme";
import TopGames from "./components/TopGames";

function App() {
  return (
    <div className="App">
<ThemeProvider theme={theme}>
      <Hero/>
      <CTA/>
      <GamesList/>
      {/* <Benefits/> */}
      <TopGames/>
      {/* <Contacts/> */}
      <AgeDisc/>
      <CookieModal/>
    </ThemeProvider>
    </div>
  );
}

export default App;
