import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';

function Form() {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the validation error when the user makes changes
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.message) {
      newErrors.message = 'Message is required';
    }

    if (Object.keys(newErrors).length === 0) {
      // No errors, open the success dialog
      setOpen(true);
    } else {
      // Update the validation errors
      setErrors(newErrors);
    }
  };

  const handleClose = () => {
    setOpen(false);
    // Clear the form fields when the success dialog is closed
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    // Clear any validation errors
    setErrors({});
  };
  

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Typography variant="h6" color="primary">Write Us a Message</Typography>
        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name} 
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Message"
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          margin="normal"
          error={!!errors.message}
          helperText={errors.message}
        />
        <Button type="submit" variant="contained" sx={{ marginTop: '.7rem' }}>
          Submit
        </Button>
      </form>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle color="primary">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>Your form has been submitted successfully.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Form;
