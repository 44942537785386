// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Benefits from "./Benefits";

function CTA() {
  return (
    <Box id="about" sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg">
        {/* Image Block */}
        {/* <img
          src={ctaBg}
          alt="CTA"
          style={{ maxWidth: '400px', width: "100%", height: 'auto' }}
        /> */}
        {/* Text Block */}
        <Grow in={true} timeout={1200} appear={true}>
          <Box mt={3}>
            <Typography variant="h3" textAlign="center" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body1" paragraph>
              Our platform is built on the principles of fair play and
              transparency. All games featured on Betrapidwin use certified
              random number generators to ensure fair outcomes, emphasizing our
              commitment to trust and integrity.
            </Typography>
            <Typography variant="body1" paragraph>
              Our journey began with a small team of passionate gamers who
              wanted to create a platform that offered a seamless and enjoyable
              gaming experience for everyone. From humble beginnings, we have
              grown into a vibrant community of gamers and developers dedicated
              to providing the best online games. Our mission is to create the
              ultimate online playground that is free and open to all.
            </Typography>
            <Typography variant="body1" paragraph>
              We are always looking to improve and expand our offerings. If you
              have any questions, feedback, or if you are a game developer
              looking to succeed on the web, please reach out to us at
              info@betrapidwin.com.
            </Typography>

        
            <Benefits />
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default CTA;
