import React from 'react';
import {topGames} from '../data/games';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent'; // Import CardContent
import Button from '@mui/material/Button'; // Import Button
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {top} from "../assets";

export default function TopGames() {
  return (
    <Container maxWidth="lg">
    <Typography textAlign="center" gutterBottom variant="h3" fontWeight="900">Top picks</Typography>
    <Typography textAlign="center" gutterBottom variant="h5">The most popular games among users</Typography>

    <Grid container spacing={3}>
    {topGames.map((game) => (
      <Grid item xs={12} sm={4} key={game.id}>
        <Card
          sx={{
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            }, backgroundColor: "primary.main400", color: "#ffffff"
          }}
        >
          <CardActionArea component={Link} to={`/game/${game.id}`} style={{ position: 'relative'}}>
           
           <img src={top} alt="top" style={{position: "absolute", top: "0", right: "0", width: "30px", height: "30px", zIndex: "10"}}/>
 <CardMedia
              component="img"
              alt={game.title}
              height="200"
              image={game.image}
              title={game.title} 
              style={{ fontSize: '14px' }}
            />
            <CardContent  textAlign="center">
              <Typography variant="h6" component="div" gutterBottom>
                {game.title}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                component={Link} mx="auto"
                to={`/game/${game.id}`}
              >
                Play now
              </Button>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    ))}
  </Grid>
  </Container>
  )
}
