import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {pattern} from "../assets";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      betrapidwin {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer" id="footer"
        sx={{
          px: 2,
          mt: 'auto',
        }} style={{
          background: `linear-gradient(90deg, rgba(29,26,41,.9) 32%, rgba(54,55,79,.8) 100%), url(${pattern})`, backgroundRepeat: "no-repeat", backgroundSize: "130%", backgroundPosition: "center",
        }}
        className="custom-shadow"
      >
         
        <Container maxWidth="sm">
        <Typography variant="h6">
          info@betrapidwin.com
         </Typography>
         <Typography variant="h6">
         +12122333399
         </Typography>
         <Typography variant="h6">
         1026 6th Ave, New York, NY 10018, United States
         </Typography>
         <br/>
         <hr style={{border: "1px solid #6337d4", opacity: "0.2"}}/>
         <br/>
          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
          <Typography variant="body1" textTransform="uppercase">

      Does not involve the use of Real Money. <br/>
      Practicing casino gaming for fun does not imply future success in real money gambling. <br/>
      This website is intended for persons over 18 years of age and is for entertainment purposes only.   </Typography>

        </Container>
      </Box>
  );
}
