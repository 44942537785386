import React from "react";
import {Container,  Box, Typography, Paper} from '@mui/material';
import Header from "./Header";
import Contacts from "./Contacts";
import TopGames from "./TopGames";

export default function About() {
  return (
    <>
      <Header />
      <Container>
        <Box sx={{ maxWidth: 1200, margin: 'auto' }}>
            <Paper elevation={3} sx={{ padding: 5, backgroundColor: "primary.main500" }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    About Betrapidwin
                </Typography>
                <Typography variant="body1" paragraph>
                Betrapidwin offers the best free online games selection, providing the most fun experience to play alone or with friends. Enjoy instant play without downloads, login, popups, or other distractions. Our games are accessible on desktop, tablet, and mobile, so you can enjoy them at home or on the go. Every month, millions of gamers from around the world play their favorite games on Betrapidwin.                </Typography>
                <Typography variant="body1" paragraph>
                    Our platform is built on the principles of fair play and transparency. All games featured on Betrapidwin use certified random number generators to ensure fair outcomes, emphasizing our commitment to trust and integrity.
                </Typography>
                <Typography variant="body1" paragraph>
                Our journey began with a small team of passionate gamers who wanted to create a platform that offered a seamless and enjoyable gaming experience for everyone. From humble beginnings, we have grown into a vibrant community of gamers and developers dedicated to providing the best online games. Our mission is to create the ultimate online playground that is free and open to all.

</Typography>
                <Typography variant="body1" paragraph>
                    Our mission is to provide a safe and entertaining environment where players can enjoy gaming without any commitments or financial implications. Betrapidwin is a haven for those who seek to enjoy the thrill of gaming in a purely social and non-competitive context.
                </Typography>
                <Typography variant="body1" paragraph>
                    Established in 2023, Betrapidwin has quickly become a leading provider in the free social gaming space, continuously expanding our game library and improving user experiences.
                </Typography>
            </Paper>
        </Box>

        <TopGames/>

        <Contacts />
      </Container>
    </>
  );
}
