import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Form from './Form';
import {favicon} from "../assets";

export default function Contacts() {
  return (
    <Box sx={{ backgroundColor: "primary.dark" }}>
    <Container maxWidth="lg" id="contacts">
      <Typography variant="h2" textAlign="center" marginBottom="2.5rem">Got any questions on your mind?</Typography>
      <Grid container justifyContent="center" textAlign="center" alignItems="center" >
      <Grid item xs={12} sm={6} mx="auto">
          <img src={favicon} alt="" style={{ maxWidth: '300px', width: "100%", height: 'auto' }}/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Form/>
        </Grid>
</Grid>
    </Container>
    </Box>
  )
}
