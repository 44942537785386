import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function CookieModal() {
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieAccepted = localStorage.getItem("cookieAccepted");
    if (!cookieAccepted) {
      setShowCookieModal(true);
    } else {
      setCookieAccepted(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    localStorage.setItem("cookieAccepted", true);
    setCookieAccepted(true);
    setShowCookieModal(false);
  };

  return (
    <div>
      {showCookieModal && !cookieAccepted && (
        <div className="cookie-block">
          <p className="cookie-text">
            This website uses cookies to offer you a better browsing experience.
            By continuing this website, you agree to the use of cookies.{" "}
          </p>
          <Link to="privacy" className="cookie-more">
            Learn more{" "}
          </Link>
          <button onClick={handleAcceptCookies} className="cookie-accept">
            Accept{" "}
          </button>
          <div className="cookie-close-wrap close-btn">
            <button
              onClick={() => setShowCookieModal(false)}
              className="cookie-close-btn"
            >
              <svg
                height="25px"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
