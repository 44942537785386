import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

const DisclaimerModal = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog open={open} onClose={handleClose} className="modal"  maxWidth="lg" scroll="paper">
        <DialogTitle>Disclaimer</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            Welcome to our social pokies website. Before using this site and/or any of the games
            (collectively, the "Services") provided by us, please read and agree to the terms and
            conditions set forth below:
          </Typography>
          <Typography variant="h6">General Information:</Typography>
      <Typography>
        We are not a gambling operator and the Services are not intended to be used for real-money gambling purposes.
      </Typography>
      <Typography>
        The Services are virtual pokies-style entertainment games, and do not involve any real money bets or prizes.
      </Typography>
      <Typography>
        We do not collect any personal information from you except for what is necessary to provide you with the Services. Any personal information you provide to us will be treated in accordance with our privacy policy.
      </Typography>
      <Typography>
        Your use of the Services is at your own risk. We do not guarantee that the Services will always be available or error-free, and we are not responsible for any malfunction, disruption, or damage to your computer system or mobile device that may occur as a result of your use of the Services.
      </Typography>
      <Typography>
        We reserve the right to modify, suspend, or discontinue the Services at any time without notice to you.
      </Typography>

      <Typography variant="h6">Responsibility for your use of the Services:</Typography>
      <Typography>
        You are solely responsible for any and all activities that occur under your account and for ensuring that your use of the Services is in compliance with all applicable laws and regulations.
      </Typography>
      <Typography>
        You agree not to use the Services for any illegal or fraudulent purpose, or to interfere with the use of the Services by others.
      </Typography>
      <Typography>
        You acknowledge that any virtual currency that we provide to you as part of the Services has no real-world value, and cannot be redeemed for cash or other prizes.
      </Typography>
      <Typography>
        You agree not to sell or transfer your account or any virtual currency that you obtain through the Services, except as expressly permitted by us.
      </Typography>

      <Typography variant="h6">Intellectual Property:</Typography>
      <Typography>
        All intellectual property rights in the Services and any content or materials contained therein (excluding any user-generated content) are owned by us or our licensors.
      </Typography>
      <Typography>
        You are not permitted to use any of the intellectual property rights in the Services or any content or materials contained therein without our prior written consent.
      </Typography>

      <Typography variant="h6">Governing Law:</Typography>
      <Typography>
        These terms and conditions shall be governed by the laws of the country in which you reside or use the Services, without giving effect to any principles of conflicts of law. You agree to submit to the exclusive jurisdiction of the courts located in the country in which you reside or use the Services for any disputes arising out of your use of the Services.
      </Typography>

      <Typography variant="h6">Acceptance of these terms and conditions:</Typography>
      <Typography>
        By using the Services, you signify your agreement to these terms and conditions. If you do not agree to these terms and conditions, you may not use the Services.
      </Typography>
      <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DisclaimerModal;
