import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: "#1d1a29",       // Dark background shade
      main: "#1d1a29",       // Main dark color
      main600: "#36374f",    // Darker variant for subtle depth
      main500: "#c7374e",    // Main accent color
      main400: "#ED5C40",    // Red accent color
      main300: "#13A35C",    // Green accent color
      main200: "#ffffff",    // Light color for contrast
      main100: "#c7374e",    // Reusing main accent color
      main50: "#36374f",     // Reusing secondary accent color
  },
  
  text: {
      primary: "#ffffff", // Light text color for dark backgrounds
  },
  
  secondary: {
      main: "#c7374e",     // Main secondary accent color
      secondary: "#36374f", // Secondary accent color for elements
  },
    success: {
        main: '#C8E4B2',
    }
  },
  typography: {
    fontFamily: 'Mulish, sans-serif',
    color: "white",
    h1: {
      xs: {fontSize: "1rem"}, 
      md: {fontSize: "5rem"},
    }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});


export default theme;
